/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects';
import {
  clearDataRequestFail,
  clearDataRequestStart,
  clearDataRequestSuccess,
  fileUploadToServerFail,
  fileUploadToServerStart,
  fileUploadToServerSuccess,
  getFileListFail,
  getFileListStart,
  getFileListSuccess,
  getUserDetailSaga,
  setProgress,
  removeListFile,
  fileDownloadFromServerStart,
  showDownloadLink,
  selfTestReportStart,
  selfTestReportSuccess,
  selfTestReportFail,
} from '../../actions';
import { errorHandler } from '../../../utils';

export function* fileUploadToServerSaga(action) {
  const { uploadFile } = window.commChannel;
  const { email, fileList = [], dispatch, selfTestCallback } = action.payload;

  yield put(fileUploadToServerStart());
  const progressCallback = data => {
    if (data.progress === 100) {
      setTimeout(() => {
        if (selfTestCallback) {
          selfTestCallback();
        }
        dispatch(removeListFile({ ...data, type: 'UPLOAD' }));
      }, 2000);
    }
    dispatch(setProgress({ ...data, type: 'UPLOAD' }));
  };
  const requestObj = fileList.map(item => ({
    ...item,
    callback: progressCallback,
  }));
  const result = yield uploadFile(email, requestObj);
  // console.log('🚀 ~ file: fileControl.js:21 ~ function*fileUploadToServerSaga ~ result:', result);
  // yield errorHandler({
  //   endpoint: `/users/${userId}`,
  //   successHandler: yield function* (response) {
  //     yield call([localStorage, 'setItem'], 'data', JSON.stringify(response.data));
  //     yield put(fileUploadToServerSuccess(response.data));
  //   },
  //   failHandler: fileUploadToServerFail,
  //   apiType: 'get',
  // });
}

export function* fileDownloadFromServerSaga(action) {
  const { downloadFile } = window.commChannel;
  const { email, fileList = [], dispatch } = action.payload;

  yield put(fileDownloadFromServerStart());
  // const userId = localStorage.getItem('userid');
  const progressCallback = data => {
    // console.log('🚀 ~ file: fileControl.js:59 ~ progressCallback ~ data:', data);
    if (data.progress === 100) {
      dispatch(showDownloadLink({ ...data, type: 'DOWNLOAD' }));
    }
    dispatch(setProgress({ ...data, type: 'DOWNLOAD' }));
  };
  const requestObj = fileList.map(item => ({
    ...item,
    callback: progressCallback,
  }));
  const result = yield downloadFile(email, requestObj);
  // yield errorHandler({
  //   endpoint: `/users/${userId}`,
  //   successHandler: yield function* (response) {
  //     yield call([localStorage, 'setItem'], 'data', JSON.stringify(response.data));
  //     yield put(fileDownloadFromServerSuccess(response.data));
  //   },
  //   failHandler: fileDownloadFromServerFail,
  //   apiType: 'get',
  // });
}

export function* getFileListSaga(action) {
  const { showDeletedFiles = false, isSelfTest = false } = action.payload;
  yield put(getFileListStart());
  yield errorHandler({
    endpoint: `/file-list?deleted=${showDeletedFiles}&isSelfTest=${isSelfTest}`,
    successHandler: yield function* (response) {
      yield put(getFileListSuccess(response.data));
      yield put(getUserDetailSaga());
    },
    failHandler: getFileListFail,
    apiType: 'get',
  });
}

export function* clearDataRequestSaga(action) {
  const { closeShowClearDataModel } = action.payload;
  yield put(clearDataRequestStart());
  // const userId = localStorage.getItem('userid');
  yield errorHandler({
    endpoint: `/clear-data`,
    successHandler: yield function* (response) {
      yield put(clearDataRequestSuccess(response.data));
      yield put(getUserDetailSaga());
      closeShowClearDataModel();
    },
    failHandler: clearDataRequestFail,
    // payload: { userId },
    apiType: 'post',
  });
}

export function* selfTestReportSaga(action) {
  const { closeModal, data } = action.payload;
  yield put(selfTestReportStart());
  yield errorHandler({
    endpoint: `/self-test-report`,
    successHandler: yield function* (response) {
      yield put(selfTestReportSuccess(response.data));
      yield put(getUserDetailSaga());
      if (closeModal) {
        closeModal();
      }
    },
    failHandler: selfTestReportFail,
    payload: data,
    apiType: 'post',
  });
}
