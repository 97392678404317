const setCookie = (name, value, hours) => {
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + hours * 60 * 60 * 1000);
  const expires = `expires=${expiration.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}`;
};

const getCookie = name => {
  const cookieArray = document.cookie.split('; ');
  for (let i = 0; i < cookieArray.length; i += 1) {
    const cookie = cookieArray[i].split('=');
    if (cookie[0] === name) {
      return cookie[1];
    }
  }
  return null; // Cookie not found
};

export { getCookie, setCookie };
