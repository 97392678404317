import React, { useState } from 'react';
import './popover.css';

const Popover = ({ title, content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="popover-section">
      <button type="button" onClick={toggleVisibility} className="link-button">
        {children}
      </button>
      {isVisible && (
        <>
          <div className="popover fade show bs-popover-right" role="tooltip">
            <div className="arrow" />
            <h3 className="popover-header">{title}</h3>
            <div className="popover-body">{content}</div>
          </div>
          <div role="presentation" className="popover-overlay" onClick={toggleVisibility} />
        </>
      )}
    </div>
  );
};

export default Popover;
