import * as actionLabels from '../../actionLabels';

export const initialState = {
  isLoading: false,
  serverUploadFileList: [],
  serverDownloadFileList: [],
  downloadFileList: null,
  totalFiles: null,
  errorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.FILE_UPLOAD_TO_SERVER_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case actionLabels.FILE_UPLOAD_TO_SERVER_SAGA: {
      const { fileList = [] } = payload;
      return {
        ...state,
        serverUploadFileList: [...state.serverUploadFileList, ...fileList],
        errorMsg: '',
      };
    }
    case actionLabels.FILE_UPLOAD_TO_SERVER_SUCCESS: {
      return {
        ...state,
        errorMsg: '',
      };
    }
    case actionLabels.FILE_UPLOAD_TO_SERVER_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };
    }
    case actionLabels.FILE_DOWNLOAD_FROM_SERVER_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case actionLabels.FILE_DOWNLOAD_FROM_SERVER_SAGA: {
      const { fileList = [] } = payload;
      return {
        ...state,
        serverDownloadFileList: [...state.serverDownloadFileList, ...fileList],
        errorMsg: '',
      };
    }
    case actionLabels.FILE_DOWNLOAD_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        errorMsg: '',
      };
    }
    case actionLabels.FILE_DOWNLOAD_FROM_SERVER_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };
    }
    case actionLabels.GET_FILE_LIST_START: {
      return {
        ...state,
        isLoading: true,
        downloadFileList: null,
        errorMsg: '',
      };
    }
    case actionLabels.GET_FILE_LIST_SUCCESS: {
      return {
        ...state,
        downloadFileList: payload.list,
        totalFiles: payload.totalFiles,
        errorMsg: '',
      };
    }
    case actionLabels.GET_FILE_LIST_FAIL: {
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };
    }
    case actionLabels.CLEAR_DATA_REQUEST_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case actionLabels.CLEAR_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        errorMsg: '',
      };
    }
    case actionLabels.CLEAR_DATA_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionLabels.SELF_TEST_REPORT_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: '',
      };
    }
    case actionLabels.SELF_TEST_REPORT_SUCCESS: {
      return {
        ...state,
        errorMsg: '',
      };
    }
    case actionLabels.SELF_TEST_REPORT_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionLabels.SET_PROGRESS: {
      const data = payload;
      const { type: progressType = 'UPLOAD' } = data;
      if (progressType === 'UPLOAD') {
        const fileList = [...state.serverUploadFileList];
        const index = fileList.findIndex(item => item.id === data.id);
        if (data.fail) {
          fileList[index].fail = data.fail;
          fileList[index].err = data.err;
        } else if (index !== -1) {
          fileList[index].progress = data.progress;
        }
        return {
          ...state,
          serverUploadFileList: fileList,
        };
      }
      if (progressType === 'DOWNLOAD') {
        const fileList = [...state.serverDownloadFileList];
        const index = fileList.findIndex(item => item.id === data.id);
        if (data.fail) {
          fileList[index].fail = data.fail;
          fileList[index].err = data.err;
        } else if (index !== -1) {
          fileList[index].progress = data.progress;
        }
        return {
          ...state,
          serverDownloadFileList: fileList,
        };
      }
      return state;
    }
    case actionLabels.REMOVE_LIST_FILE: {
      const data = payload;
      const { type: progressType = 'UPLOAD' } = data;
      if (progressType === 'UPLOAD') {
        const fileList = [...state.serverUploadFileList];
        const list = fileList.filter(item => item.id !== data.id);
        return {
          ...state,
          serverUploadFileList: list,
        };
      }
      if (progressType === 'DOWNLOAD') {
        const fileList = [...state.serverDownloadFileList];
        const list = fileList.filter(item => item.id !== data.id);
        return {
          ...state,
          serverDownloadFileList: list,
        };
      }
      return state;
    }
    case actionLabels.SHOW_DOWNLOAD_LINK: {
      const data = payload;
      const { type: progressType = 'UPLOAD' } = data;
      if (progressType === 'DOWNLOAD') {
        const fileList = [...state.serverDownloadFileList];
        const index = fileList.findIndex(item => item.id === data.id);
        fileList[index].progress = data.progress;
        fileList[index].downloadFilePath = data.downloadFilePath;
        return {
          ...state,
          serverDownloadFileList: fileList,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
