export const RESET_APP = 'RESET_APP';

export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_SAGA = 'SIGNUP_SAGA';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const GET_READY_TO_USE_SAGA = 'GET_READY_TO_USE_SAGA';
export const GET_READY_TO_USE_START = 'GET_READY_TO_USE_START';
export const GET_READY_TO_USE_SUCCESS = 'GET_READY_TO_USE_SUCCESS';
export const GET_READY_TO_USE_FAIL = 'GET_READY_TO_USE_FAIL';

export const FORGOT_PASSWORD_SAGA = 'FORGOT_PASSWORD_SAGA';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_SAGA = 'RESET_PASSWORD_SAGA';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const CHANGE_PASSWORD_SAGA = 'CHANGE_PASSWORD_SAGA';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const RESEND_OTP_SAGA = 'RESEND_OTP_SAGA';
export const RESEND_OTP_START = 'RESEND_OTP_START';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL';

export const VERIFY_OTP_SAGA = 'VERIFY_OTP_SAGA';
export const VERIFY_OTP_START = 'VERIFY_OTP_START';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const LOGOUT_SAGA = 'LOGOUT_SAGA';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const AUTHENTICATION_VALIDATOR = 'AUTHENTICATION_VALIDATOR';
export const RESET_ERROR_MSG = 'RESET_ERROR_MSG';

export const GET_USER_DETAIL_SAGA = 'GET_USER_DETAIL_SAGA';
export const GET_USER_DETAIL_START = 'GET_USER_DETAIL_START';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_CURRENT_USER_DETAIL_SUCCESS = 'GET_CURRENT_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAIL = 'GET_USER_DETAIL_FAIL';
export const GET_CURRENT_USER_DETAIL_FAIL = 'GET_CURRENT_USER_DETAIL_FAIL';

export const EDIT_USER_DETAIL_SAGA = 'EDIT_USER_DETAIL_SAGA';
export const EDIT_USER_DETAIL_START = 'EDIT_USER_DETAIL_START';
export const EDIT_USER_DETAIL_SUCCESS = 'EDIT_USER_DETAIL_SUCCESS';
export const EDIT_USER_DETAIL_FAIL = 'EDIT_USER_DETAIL_FAIL';

export const EDIT_AUTO_SYNC_INFO_SAGA = 'EDIT_AUTO_SYNC_INFO_SAGA';
export const EDIT_AUTO_SYNC_INFO_START = 'EDIT_AUTO_SYNC_INFO_START';
export const EDIT_AUTO_SYNC_INFO_SUCCESS = 'EDIT_AUTO_SYNC_INFO_SUCCESS';
export const EDIT_AUTO_SYNC_INFO_FAIL = 'EDIT_AUTO_SYNC_INFO_FAIL';

export const SHOW_STRIPE_MODAL = 'SHOW_STRIPE_MODAL';
export const HIDE_STRIPE_MODAL = 'HIDE_STRIPE_MODAL';
