import React from 'react';

export const guestRoutes = [
  {
    path: '/',
    name: 'Signin',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Login')),
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/ForgotPassword')),
  },
  {
    path: '/otp-verification',
    name: 'OTP Verification',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/OTPVerification')),
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/ResetPassword')),
  },
  {
    path: '/sign-up',
    name: 'Signup',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Signup')),
  },
  {
    redirectRoute: true,
    name: 'Login',
    path: '/',
  },
];

export const userVerificationRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/UserVerification')),
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/',
    // rootRedirect: true,
  },
];

export const user2FAVerificationRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/User2FAVerification')),
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/',
    // rootRedirect: true,
  },
];

export const userPlanRoutes = [
  {
    path: '/privacy',
    name: 'Privacy',
    exact: true,
    component: React.lazy(() => import('../../views/user/NewUserOnboard/Privacy/Privacy')),
  },
  {
    path: '/home',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/UserPlan')),
  },
  {
    redirectRoute: true,
    name: 'privacyRedirect',
    path: '/privacy',
    // rootRedirect: true,
  },
];

export const underMaintenanceRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/UnderMaintenance')),
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/',
    // rootRedirect: true,
  },
];

export const userReactivate = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/UserReactivate')),
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/',
    // rootRedirect: true,
  },
];

export const userRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: React.lazy(() => import('../../views/user/MainApp/Home/Home')),
  },
  // {
  //   path: '/upload',
  //   name: 'Upload',
  //   exact: true,
  //   component: React.lazy(() => import('../../views/user/MainApp/Upload/Upload')),
  // },
  {
    path: '/files/:type',
    name: 'Download',
    exact: true,
    component: React.lazy(() => import('../../views/user/MainApp/Download/Download')),
  },
  {
    path: '/profile/:screen',
    name: 'Profile',
    exact: true,
    component: React.lazy(() => import('../../views/user/MainApp/Profile/Profile')),
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/',
    // rootRedirect: true,
  },
];

export const userOnboard = [
  {
    path: '/setup',
    name: 'Setup',
    exact: true,
    component: React.lazy(() => import('../../views/user/UserOnboard/Setup/Setup')),
  },
  // {
  //   path: '/all-set',
  //   name: 'Home',
  //   exact: true,
  //   component: React.lazy(() =>
  //     import('../../views/user/UserOnboard/CongratulationsScreen/CongratulationsScreen'),
  //   ),
  //   components: {},
  // },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/setup',
    // rootRedirect: true,
  },
];

export const newUserDeviceOnboard = [
  {
    path: '/device-setup',
    name: 'Device Setup',
    exact: true,
    component: React.lazy(() => import('../../views/user/NewUserDeviceOnboard')),
  },
  {
    path: '/all-set',
    name: 'Home',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserOnboard/CongratulationsScreen/CongratulationsScreen'),
    ),
    components: {},
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/device-setup',
    // rootRedirect: true,
  },
];

export const oldUserDeviceOnboard = [
  {
    path: '/device-setup',
    name: 'Device Setup',
    exact: true,
    component: React.lazy(() => import('../../views/user/OldUserDeviceOnboard')),
  },
  {
    path: '/all-set',
    name: 'Home',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserOnboard/CongratulationsScreen/CongratulationsScreen'),
    ),
    components: {},
  },
  {
    redirectRoute: true,
    name: 'homeRedirect',
    path: '/device-setup',
    // rootRedirect: true,
  },
];

export const newUserOnboard = [
  {
    path: '/setup',
    name: 'Setup',
    exact: true,
    component: React.lazy(() => import('../../views/user/NewUserOnboard/CreateToken/CreateToken')),
  },
  // {
  //   path: '/all-set',
  //   name: 'Home',
  //   exact: true,
  //   component: React.lazy(() =>
  //     import('../../views/user/UserOnboard/CongratulationsScreen/CongratulationsScreen'),
  //   ),
  // },
  {
    redirectRoute: true,
    name: 'setupRedirect',
    path: '/setup',
    // rootRedirect: true,
  },
];
