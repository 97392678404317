import * as actionLabels from '../../actionLabels';

export const loginStart = () => ({
  type: actionLabels.LOGIN_START,
});

export const login = payload => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});

export const loginSuccess = payload => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const loginFail = payload => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

export const signupStart = () => ({
  type: actionLabels.SIGNUP_START,
});

export const signupSaga = payload => ({
  type: actionLabels.SIGNUP_SAGA,
  payload,
});

export const signupSuccess = payload => ({
  type: actionLabels.SIGNUP_SUCCESS,
  payload,
});

export const signupFail = payload => ({
  type: actionLabels.SIGNUP_FAIL,
  payload,
});

export const getReadyToUseStart = () => ({
  type: actionLabels.GET_READY_TO_USE_START,
});

export const getReadyToUseSaga = payload => ({
  type: actionLabels.GET_READY_TO_USE_SAGA,
  payload,
});

export const getReadyToUseSuccess = payload => ({
  type: actionLabels.GET_READY_TO_USE_SUCCESS,
  payload,
});

export const getReadyToUseFail = payload => ({
  type: actionLabels.GET_READY_TO_USE_FAIL,
  payload,
});

export const forgotPasswordStart = () => ({
  type: actionLabels.FORGOT_PASSWORD_START,
});

export const forgotPasswordSaga = payload => ({
  type: actionLabels.FORGOT_PASSWORD_SAGA,
  payload,
});

export const forgotPasswordSuccess = payload => ({
  type: actionLabels.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFail = payload => ({
  type: actionLabels.FORGOT_PASSWORD_FAIL,
  payload,
});

export const resetPasswordStart = () => ({
  type: actionLabels.RESET_PASSWORD_START,
});

export const resetPasswordSaga = payload => ({
  type: actionLabels.RESET_PASSWORD_SAGA,
  payload,
});

export const resetPasswordSuccess = payload => ({
  type: actionLabels.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFail = payload => ({
  type: actionLabels.RESET_PASSWORD_FAIL,
  payload,
});

export const changePasswordStart = () => ({
  type: actionLabels.CHANGE_PASSWORD_START,
});

export const changePasswordSaga = payload => ({
  type: actionLabels.CHANGE_PASSWORD_SAGA,
  payload,
});

export const changePasswordSuccess = payload => ({
  type: actionLabels.CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFail = payload => ({
  type: actionLabels.CHANGE_PASSWORD_FAIL,
  payload,
});

export const resendOTPStart = () => ({
  type: actionLabels.RESEND_OTP_START,
});

export const resendOTPSaga = payload => ({
  type: actionLabels.RESEND_OTP_SAGA,
  payload,
});

export const resendOTPSuccess = payload => ({
  type: actionLabels.RESEND_OTP_SUCCESS,
  payload,
});

export const resendOTPFail = payload => ({
  type: actionLabels.RESEND_OTP_FAIL,
  payload,
});

export const verifyOTPStart = () => ({
  type: actionLabels.VERIFY_OTP_START,
});

export const verifyOTPSaga = payload => ({
  type: actionLabels.VERIFY_OTP_SAGA,
  payload,
});

export const verifyOTPSuccess = payload => ({
  type: actionLabels.VERIFY_OTP_SUCCESS,
  payload,
});

export const verifyOTPFail = payload => ({
  type: actionLabels.VERIFY_OTP_FAIL,
  payload,
});

export const logoutStart = payload => ({
  type: actionLabels.LOGOUT_START,
  payload,
});
export const logout = payload => ({
  type: actionLabels.LOGOUT_SAGA,
  payload,
});

export const logoutSuccess = payload => ({
  type: actionLabels.LOGOUT_SUCCESS,
  payload,
});

export const logoutFailed = payload => ({
  type: actionLabels.LOGOUT_FAILED,
  payload,
});

export const authenticationValidator = () => ({
  type: actionLabels.AUTHENTICATION_VALIDATOR,
});

export const resetErrorMsg = () => ({
  type: actionLabels.RESET_ERROR_MSG,
});

export const getUserDetailSaga = payload => ({
  type: actionLabels.GET_USER_DETAIL_SAGA,
  payload,
});

export const getUserDetailStart = () => ({
  type: actionLabels.GET_USER_DETAIL_START,
});

export const getUserDetailSuccess = payload => ({
  type: actionLabels.GET_USER_DETAIL_SUCCESS,
  payload,
});

export const getCurrentUserDetailSuccess = payload => ({
  type: actionLabels.GET_CURRENT_USER_DETAIL_SUCCESS,
  payload,
});

export const getUserDetailFail = payload => ({
  type: actionLabels.GET_USER_DETAIL_FAIL,
  payload,
});

export const getCurrentUserDetailFail = payload => ({
  type: actionLabels.GET_CURRENT_USER_DETAIL_FAIL,
  payload,
});

export const editUserDetailSaga = payload => ({
  type: actionLabels.EDIT_USER_DETAIL_SAGA,
  payload,
});

export const editUserDetailStart = () => ({
  type: actionLabels.EDIT_USER_DETAIL_START,
});

export const editUserDetailSuccess = payload => ({
  type: actionLabels.EDIT_USER_DETAIL_SUCCESS,
  payload,
});

export const editUserDetailFail = payload => ({
  type: actionLabels.EDIT_USER_DETAIL_FAIL,
  payload,
});

export const editAutoSyncInfoSaga = payload => ({
  type: actionLabels.EDIT_AUTO_SYNC_INFO_SAGA,
  payload,
});

export const editAutoSyncInfoStart = () => ({
  type: actionLabels.EDIT_AUTO_SYNC_INFO_START,
});

export const editAutoSyncInfoSuccess = payload => ({
  type: actionLabels.EDIT_AUTO_SYNC_INFO_SUCCESS,
  payload,
});

export const editAutoSyncInfoFail = payload => ({
  type: actionLabels.EDIT_AUTO_SYNC_INFO_FAIL,
  payload,
});

export const showStripeModal = payload => ({
  type: actionLabels.SHOW_STRIPE_MODAL,
  payload,
});

export const hideStripeModal = payload => ({
  type: actionLabels.HIDE_STRIPE_MODAL,
  payload,
});
