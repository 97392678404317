/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { authenticationValidator } from './store/actions';
// import { Spinner } from './components';
import { guestRoutes } from './routes';

import 'bootstrap/dist/js/bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/owl.carousel.min.css';

// Custom styles
import './assets/css/style.css';
import './assets/css/animate.css';
import './assets/css/svg-icons-animate.css';
import './assets/css/dev.css';
import './index.css';
import './assets/css/main.scss';
import { ErrorBoundary, Loader } from './components';

function App() {
  const tokenPresent = !!useSelector(state => state.auth.authToken);
  const { authToken, isLoginLoading, userData } = useSelector(state => state.auth);
  const [mounted, setMounted] = useState(false);
  const [showPromotionScreen, setShowPromotionScreen] = useState(false);
  const [isWindowLoading, setIsWindowLoading] = useState(true);
  // const role = !!useSelector(state => state.auth.userRole);
  const pathname = window.location.pathname.split('/')[1];

  const dispatch = useDispatch();

  const shareDataToApp = async () => {
    const { ipcRenderer, passAppDataPath } = window.commChannel;
    const appPaths = await ipcRenderer
      .invoke('fetch-data-path')
      .then(responseData => responseData)
      .catch(error => 'ERROR');
    passAppDataPath(appPaths);
    console.log('🚀 ~ file: App.js:40 ~ shareDataToApp ~ appPaths:', appPaths.userData);
  };

  const checkWindowLoad = () => {
    if (window.commChannel && window.commChannel.ipcRenderer) {
      setIsWindowLoading(false);
      shareDataToApp();
    }
  };

  useEffect(() => {
    if (window.commChannel && window.commChannel.ipcRenderer) {
      dispatch(authenticationValidator());
      checkWindowLoad();
      setMounted(true);
    } else {
      setShowPromotionScreen(true);
      setTimeout(() => {
        window.location.replace('https://efilesecure.com');
      }, [2000]);
    }
  }, []);

  useEffect(() => {
    checkWindowLoad();
  }, [authToken]);

  const updateScheduler = async () => {
    if (window.commChannel) {
      const { ipcRenderer } = window.commChannel;
      await ipcRenderer.invoke('update-scheduler', tokenPresent, userData);
    }
  };

  useEffect(() => {
    updateScheduler();
  }, [userData]);

  const redirectHandler = () => {
    const guestRoute = guestRoutes
      .filter(item => item.redirectRoute === undefined)
      .map(item => item.path);
    return !guestRoute.includes(`/${pathname}`) && localStorage.getItem('authToken') == null ? (
      <Redirect to="/" />
    ) : null;
  };
  let mainContent;
  const renderContent = () => {
    mainContent = (
      <>
        {guestRoutes.map(
          route =>
            route.redirectRoute === undefined && (
              <Route key={route.name} path={route.path} exact={route.exact} name={route.name}>
                <route.component />
              </Route>
            ),
        )}
        {redirectHandler()}
      </>
    );
    if (tokenPresent) {
      mainContent = (
        <>
          <Route path="/" component={lazy(() => import('./views/MainContainer/MainContainer'))} />
        </>
      );
    }
    return mainContent;
  };

  useEffect(() => {
    renderContent();
  }, [authToken]);

  return (
    <Suspense fallback={<Loader isFullLoader />}>
      {showPromotionScreen ? (
        <div>Redirecting...</div>
      ) : (
        <BrowserRouter>
          <ErrorBoundary>
            <Switch>
              {isLoginLoading || isWindowLoading ? (
                <Loader className="h-100vh" isFullLoader />
              ) : (
                renderContent()
              )}
            </Switch>
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </Suspense>
  );
}

export default App;
